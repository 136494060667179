/*
 * Flood Light START
 * Sample: onclick="callFloodlight('view-warranty-booklet');"
 */
function callFloodlight(tagId, ModelName, ModelYear) {

  // var axel = Math.random() + "";
  // var a = axel * 10000000000000;
  // console.log('callFloodlight: ', tagId);
  // switch (tagId) {

  //   // home page  home-page
  //   case "home-page":
  //       gtag('event', 'conversion', {
  //         'allow_custom_scripts': true,
  //         'send_to': 'DC-4114412/armacpv/endma0+unique'
  //       });

  //     break;


  //   //  NO-ZIP page Place on Inventory landing page
  //   case "inventory-search-landing":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma00+standard'
  //     });

  //     break;

  //   //  Inventory Search Listings - Fire when user searches inventory. Pass back u1=ModelName when possible.. 
  //   case "inventory-search-listings":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma000;u1=[' + ModelName + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     // gtag('event', 'conversion', {
  //     //   'allow_custom_scripts': true,
  //     //   'u1': ModelName,
  //     //   'send_to': 'DC-4114412/armacpv/endma000+standard'
  //     // });

  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'session_id': floodLightSessionManager(),
  //       'u1': ModelName,
  //       'send_to': 'DC-4114412/armacpv/endma000+per_session'
  //     });

  //     break;

  //   // Fire when user clicks on Details. Pass back u1=ModelName.
  //   case "inventory-search-detail":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma001;u1=[' + ModelName + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'u1': ModelName,
  //       'send_to': 'DC-4114412/armacpv/endma001+standard'
  //     });
  //     break;

  //   //FROM MODEL_LIBRARY ONCLICK FIRE THIS - Place on each model and pass back u1=ModelName and u2=ModelYear. 
  //   case "model-details":
  //     //  document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma004;u1=[' + ModelName + '];u2=[' + ModelYear + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'u1': ModelName,
  //       'u2': ModelYear,
  //       'send_to': 'DC-4114412/armacpv/endma004+standard'
  //     });

  //     break;

  //   // View Benefits Button
  //   case "program-view-benefits":
  //     //  document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma008;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma008+standard'
  //     });
  //     break;

  //   //  Program Download Brochures - Place on the 3 Download Brochures buttons.
  //   case "program-download-brochures":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma00-;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma00-+standard'
  //     });

  //     break;

  //   //  Fire when user either enters zip or use location.
  //   case "dealer-locator-results":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma00a;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'session_id': floodLightSessionManager(),
  //       'send_to': 'DC-4114412/armacpv/endma00a+per_session'
  //     });

  //     break;

  //   //  Estimate Payments Results - Fire once per session on calculate monthly payment.
  //   case "estimate-payments-results":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma005;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'session_id': floodLightSessionManager(),
  //       'send_to': 'DC-4114412/armacpv/endma005+per_session'
  //     });
  //     break;

  //   //  Dealer Chat - Fire when user clicks Live Chat or Text under Dealer Information.
  //   case "dealer-chat":
  //     //  document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma002+standard'
  //     });
  //     break;

  //   //  Contact Dealer - Fire after user submits their information to contact dealer.
  //   case "contact-dealer":
  //     //document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma003+standard'
  //     });
  //     break;

  //   //  From Dealer Locator, floodlight should fire when user searches dealer inventory
  //   case "dealer-see-certified":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma009;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma009+standard'
  //     });

  //     break;


  //   //  when on dealer inventory and clicks Details
  //   case "dealer-inventory-details":
  //     // document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114412.fls.doubleclick.net/activityi;src=4114412;type=armacpv;cat=endma00b;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
  //     gtag('event', 'conversion', {
  //       'allow_custom_scripts': true,
  //       'send_to': 'DC-4114412/armacpv/endma00b+standard'
  //     });

  //     break;


    //--------------

    //Inventory Search Results:"Fire once user has searched and been provided listings for a model 
    //ModelName is required
    //ModelYear is required
    // case "inventory-search-results":
    //   document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endti00;u1=[' + ModelName + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
    //   break;


    //Inventory Search Details:Fire once a user clicks on "see details", would like to fire on the page load but if unavailable would settle for click on "see details button"
    //ModelName is required
    //ModelYear is required
    // case "inventory-search-details":
    //   document.getElementById('FloodLightTags').innerHTML = '<iframe src="https://4114413.fls.doubleclick.net/activityi;src=4114413;type=hrmhcpv;cat=endmi0;u1=[' + ModelName + '];u2=[' + ModelYear + '];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>';
    //   break;

  // }


}
/*
 * FloodLight END
 * 
 */

// Firefox 'Tracking Protection' prevents the satelliteLib from loading.
// Extend this object with any function you would normally call on _satellite.
if (!_satellite) {
  console.log("AdobeDTM satelliteLib tracker failed to load. You are probably running FireFox with 'Tracking Protection' enabled. Subsequent '_satellite' calls will be no-ops.");
  var _satellite = {
    setVar: () => { },
    track: () => { },
    pageBottom: () => { },
  }
}

$(document).ready(function () {


  /**Adobe lanuch add att. for CCPA */
  $(".footer-row3 ul li:nth-child(4) a").attr('data-link-id', 'do_not_sell');
  $(".footer-row3 ul li:nth-child(3) a").attr('data-link-id', 'privacy_policy');

  

  /*
   * DTM: digitalDataLayer Tracking  start
   */

  //_satellite.setVar();
  // $(document.body).on('click', '[data-setvar]', function (e) {
  //   var $this = $(this);
  //   var setVarValue = $this.data("setvar");
  //   var setVarName = $this.data("setvarname");


  //   _satellite.setVar(setVarName, setVarValue);
  //   console.log('_satellite.setVar FIRED: ', setVarName + ' ' + setVarValue);

  // });

  //_satellite.track
  // $(document.body).on('click', '[data-tracking]', function (e) {
  //   var $this = $(this);
  //   var trackingValue = $this.data("tracking");

  //   //track needs to fire after setVar, adding a delay
  //   // setTimeout(function() {
  //   _satellite.track(trackingValue);
  //   console.log('_satellite.track FIRED: ', trackingValue);

  //   // }, 500);

  // });



  /*
   * DTM: digitalDataLayer Tracking  END
   */

  // Function to check query string for a value
  // function getQueryStringValue(variable) {
  //   var query = window.location.search.substring(1).toLowerCase();
  //   var vars = query.split("&");
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("=");
  //     if (pair[0] == variable) {
  //       return pair[1];
  //     }
  //   }
  //   return (false);
  // }

  /*
 * Tracking - CampaignName start
 */
  // Function to check query string for a value - NOT LOWERCASE
  // function getQueryStringValueAny(variable) {
  //   var query = window.location.search.substring(1);
  //   var vars = query.split("&");
  //   for (var i = 0; i < vars.length; i++) {
  //     var pair = vars[i].split("=");
  //     if (pair[0].toLowerCase() === variable.toLowerCase()) {
  //       return pair[1];
  //     }
  //   }
  //   return (false);
  // }
  // get the value for CID
  //lowercase value only
  // var _CampaignName = getQueryStringValueAny('cid');

  // if (_CampaignName) {

  //   var inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
  //   //set the cookie with the CID value
  //   Cookies.set('_CampaignName', _CampaignName);

  // }

  /*
   * Tracking - CampaignName end
   */
});
// Ready end


/*
 * floodlight session manager start
 */

// function floodLightSessionManager() {

//   var floodlightSessionValue = Cookies.get('floodlightSessionValue');
//   var randomValue = randomValueGenerator(16, '0123456789abcdefghijklmnopqrstuvwxyz');
//   if (floodlightSessionValue) {
//     return floodlightSessionValue;
//   } else {
//     Cookies.set('floodlightSessionValue', randomValue, {
//       //expire in 30 minutes
//       expires: 1 / 48
//     });
//     return randomValue;

//   }
// };

// random Value Generator
// function randomValueGenerator(length, chars) {
//   var result = '';
//   for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
//   return result;
// }


//When user clicks away from the page, remove the cookie "floodlightSessionValue"
window.addEventListener('beforeunload', function (e) {
  // Cancel the event as stated by the standard.
  // e.preventDefault();
  // Remove the cookie
  // Cookies.remove('floodlightSessionValue');
});
/*
 * floodlight session manager end
 */

 